import queryString from "query-string"
import { getConfig, getEnums } from "./localstorage"
import { iconWarning } from "./icons"
import { getCookieAuth } from "lib/cookie"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
dayjs.extend(utc)

export function convertPermission(permission) {
  const list = Object.keys(permission)
  let objPermission = {}
  for (let i = 0; i < list.length; i++) {
    let __path = list[i]?.split(".")[0]
    let values = permission[list[i]]
    if (!objPermission[__path]) objPermission[__path] = {}
    for (let i = 0; i < values.length; i++) {
      if (values[i].method === "*") {
        objPermission[__path] = {
          DELETE: true,
          POST: true,
          PUT: true,
          GET: true,
          CLOSE: true,
          DETAIL: true,
          ATRACT: true,
          APPROVAL: true,
          VIEW: true,
          IMPORT: true,
          EXPORT: true
        }
        break
      } else {
        objPermission[__path][values[i].method] = true
      }
    }
  }
  return objPermission
}

export function downloadObjectAsJson(exportObj, exportName) {
  var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj))
  var downloadAnchorNode = document.createElement("a")
  downloadAnchorNode.setAttribute("href", dataStr)
  downloadAnchorNode.setAttribute("download", exportName + ".json")
  document.body.appendChild(downloadAnchorNode)
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

export function convertDurationToClock(n) {
  let h = Math.floor(n / 3600)
  let m = n % 3600
  let _m = Math.floor(m / 60)
  let s = m % 60
  if (h === 0) {
    h = ""
  } else {
    h = h + ":"
  }
  if (_m < 10) _m = "0" + _m
  if (s < 10) s = "0" + s
  return h + _m + ":" + s
}

export function convertTranslates(array) {
  let resource = {}
  for (let i = 0; i < array.length; i++) {
    resource[array[i].file_element.element_id] = array[i].translation_content
  }
  return resource
}
export function convertModal({ text, t }) {
  return {
    className: "modal_confirm",
    icon: null,
    centered: true,
    title: (
      <div>
        <div className="flex justify-center">{iconWarning}</div>
        <div className="text-center">{t("I57_6138;611_93748")}</div>
      </div>
    ),
    cancelText: t("I57_6138;611_95467;30_32137"),
    content: <div className="text-center text-gray-500">{text}</div>,
    okText: t("I57_6138;611_95468;611_95349")
  }
}
export function convertTransalte() {
  let translates = {
    en: "English"
  }
  const translatesStore = localStorage.getItem("language")
  if (translatesStore) translates = JSON.parse(translatesStore)
  let rows = Object.keys(translates).map((i) => {
    return {
      label: translates[i],
      value: i
    }
  })
  return rows
}

export function converDataTree(_list, filter) {
  let list = [..._list]
  function loop(__list) {
    for (let i = 0; i < __list.length; i++) {
      __list[i].value = __list[i].id
      __list[i].title = __list[i].name

      if (__list[i].children && __list[i].children.length > 0) {
        if (filter) {
          __list[i].selectable = true
        } else {
          __list[i].selectable = false
        }
        loop(__list[i].children)
      }
    }
  }
  loop(list)
  return list
}

export const paramsUrl = {
  get: () => {
    return queryString.parse(window.location.search)
  },
  set: (params) => {
    const currentUrlParams = queryString.stringify(params, {
      skipNull: true
    })
    window.history.replaceState(
      {},
      null,
      `${window.location.pathname}?${currentUrlParams.toString()}`
    )
  }
}

export function getSearchParams() {
  return queryString.parse(window.location.search)
}

export function getRole() {
  const role = localStorage.getItem("role")
  return role || false
}

export function fomatCurrency(number) {
  if (number) {
    return parseInt(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
  }
  return 0
}

export function getToken() {
  return localStorage.getItem("accessToken")
}

export function convertEnumToDropdown(key) {
  const enums = getEnums()
  return enums[key]
}

export function getServiceCode(objectCode) {
  const application = JSON.parse(localStorage.getItem("application"))
  return `${application?.organization?.code}-${objectCode}`
}

export function isDriver(user) {
  const groups = user.groups[0]
  return groups.is_enduser === true && groups.name.toLowerCase().includes("driver")
}

export function isAdmin(user) {
  const groups = user.groups[0]
  return groups.is_admin
}

export function navigateToLoginPage() {
  window.location.href = `${process.env.REACT_APP_SSO_URL}`
}

export function convertLocalToUtc(time) {
  return dayjs(time).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
}

export function convertUtcToLocal(time) {
  // Parse UTC time and convert to local time
  return dayjs.utc(time).local().format("YYYY-MM-DD HH:mm")
}
